// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-galaxies-jsx": () => import("./../src/pages/galaxies.jsx" /* webpackChunkName: "component---src-pages-galaxies-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-landscapes-jsx": () => import("./../src/pages/landscapes.jsx" /* webpackChunkName: "component---src-pages-landscapes-jsx" */),
  "component---src-pages-milkyway-jsx": () => import("./../src/pages/milkyway.jsx" /* webpackChunkName: "component---src-pages-milkyway-jsx" */),
  "component---src-pages-nebulae-jsx": () => import("./../src/pages/nebulae.jsx" /* webpackChunkName: "component---src-pages-nebulae-jsx" */),
  "component---src-pages-solarsystem-jsx": () => import("./../src/pages/solarsystem.jsx" /* webpackChunkName: "component---src-pages-solarsystem-jsx" */),
  "component---src-pages-starclusters-jsx": () => import("./../src/pages/starclusters.jsx" /* webpackChunkName: "component---src-pages-starclusters-jsx" */)
}

